import * as React from "react";
import { useTranslation } from "gatsby-plugin-react-i18next";
import { graphql } from "gatsby";

import Layout from "../components/layout";
import Seo from "../components/seo";
import { EventSummary } from "../components/event-summary";
import { events } from "../mock/events";

const EventsPage = ({ data, location }) => {
  const { t } = useTranslation("events");
  const siteTitle = data?.site?.siteMetadata?.title || ``;

  if (events.length === 0) {
    return (
      <Layout location={location || `/`} title={siteTitle}>
        <Seo title={t("Anstehende Veranstaltungen")} />
        <p>{t("Im Moment keine geplant.")}</p>
      </Layout>
    );
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title={t("Anstehende Veranstaltungen")} />
      <ul className="list-style-none container mx-auto lg:w-1/2">
        {events.map((event, i) => (
          <EventSummary event={event} key={event.id} />
        ))}
      </ul>
    </Layout>
  );
};

export default EventsPage;

export const pageQuery = graphql`
  query ($language: String!) {
    site {
      ...SiteMetadata
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ...I18n
        }
      }
    }
  }
`;
